import React from 'react';
import {
    Create,
    DeleteButton,
    DisabledInput,
    Edit,
    EditButton,
    List,
    SimpleForm,
    TextField,
    SelectInput,
    LongTextInput,
    TextInput,
    FormDataConsumer,
    BooleanInput,
    maxLength, regex, required
} from 'react-admin';
import {Avatar, Button, Chip, Divider, Grid, Card, CardActions, CardContent, Toolbar} from "@material-ui/core";
import * as PropTypes from "prop-types";

const styles = {
    card: {
        display: "flex",
        flexDirection: "column",
        margin: "8px"
    },
    actions: {
        justifyContent: "space-between",
        backgroundColor: "#fafafa"
    }
};

// Validation configuration
const validateCTATitle = [maxLength(50, "Bitte maximal 50 Zeichen eingeben")]
const validateCTAButton = [maxLength(30, "Bitte maximal 30 Zeichen eingeben")]
const validateCTACopy = [maxLength(420, "Bitte maximal 420 Zeichen eingeben")]
const validateLinkTarget = [regex(/^\/([a-z0-9]+\/)?[^\/.]+$/, "Falsches Linkformat. Nur interne Links mit führendem Slash (Korrekt: /wettbewerb)"), required("Dieses Feld ist ein Pflichtfeld")]

// Linked select boxes configuration
const pages = ['home', 'projekte', 'bewerbung'];
const positions = {
    home: [1, 2, 3],
    projekte: [1],
    bewerbung: [1],
};
const toChoices = items => items.map(item => ({ id: item, name: item }));

function SaveButton(props) {
    return null;
}

SaveButton.propTypes = {
    redirect: PropTypes.string,
    label: PropTypes.string,
    submitOnEnter: PropTypes.bool
};
const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="post.action.save_and_show"
            redirect="show"
            submitOnEnter={true}
        />
        <SaveButton
            label="post.action.save_and_add"
            redirect={false}
            submitOnEnter={false}
            variant="flat"
        />
    </Toolbar>
);

// CallsToAction card preview configuration
const CTAGrid = ({ ids, data, basePath }) => (
    <Grid container>
        {ids.map(id =>
            <Grid item component={Card} xs={12} md={5} style={styles.card}>
                <CardActions style={styles.actions}>
                    <EditButton resource="cta" basePath={basePath} record={data[id]} />
                    <Chip
                        avatar={<Avatar style={{width: "24px", height: "24px", marginLeft: "4px"}}>{data[id].position}</Avatar>}
                        label={`${data[id].page[0].toUpperCase()}${data[id].page.slice(1)}`}
                        color="secondary"/>
                    <DeleteButton resource="cta" basePath={basePath} record={data[id]} />
                </CardActions>
                <Divider/>
                <CardContent>
                    <TextField record={data[id]} source="headline" style={{fontWeight: "700", fontSize: "1.25rem", marginBottom: "8px"}}/>
                    <p><TextField record={data[id]} source="copy" /></p>
                    <Button variant="contained" disabled><TextField record={data[id]} source="buttonText" /></Button>
                </CardContent>
            </Grid>
        )}
    </Grid>
);
CTAGrid.defaultProps = {
    data: {},
    ids: [],
};

export const CTAList = (props) => (
    <List title="Calls-To-Action" {...props}>
        <CTAGrid />
    </List>
);

export const CTAEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <DisabledInput fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput fullWidth label="Seite" source="page" choices={toChoices(pages)} />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <SelectInput
                                fullWidth
                                source="position"
                                choices={
                                    formData.page
                                        ? toChoices(positions[formData.page])
                                        : []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Text" source="buttonText" validate={validateCTAButton}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectInput fullWidth label="Button-Farbe" source="buttonColor" choices={[
                        { id: 'primary', name: 'Primärfarbe' },
                        { id: 'secondary', name: 'Sekundärfarbe' },
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Link-URL" source="linkUrl" validate={validateLinkTarget} />
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Überschrift" source="headline" validate={validateCTATitle}/>
                </Grid>
                <Grid item xs={12}>
                    <LongTextInput label="Fließtext" source="copy" validate={validateCTACopy}/>
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput label="Outline-Button?" source="outline" />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput label="Weißer CTA-Text?" source="textWhite" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const CTACreate = props => (
    <Create {...props}>
        <SimpleForm redirect={false}>
            <Grid container>
                <Grid item xs={12}>
                    <DisabledInput fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput fullWidth label="Seite" source="page" choices={toChoices(pages)} />
                </Grid>
                <Grid item xs={6}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <SelectInput
                                fullWidth
                                source="position"
                                choices={
                                    formData.page
                                        ? toChoices(positions[formData.page])
                                        : []
                                }
                                {...rest}
                            />
                        )}
                    </FormDataConsumer>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Text" source="buttonText" validate={validateCTAButton}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectInput fullWidth label="Button-Farbe" source="buttonColor" choices={[
                        { id: 'primary', name: 'Primärfarbe' },
                        { id: 'secondary', name: 'Sekundärfarbe' },
                    ]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput fullWidth label="Button-Link-URL" source="linkUrl"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Überschrift" source="headline" validate={validateCTATitle}/>
                </Grid>
                <Grid item xs={12}>
                    <LongTextInput label="Fließtext" source="copy" validate={validateCTACopy}/>
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput label="Outline-Button?" source="outline" />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput label="Weißer CTA-Text?" source="textWhite" />
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
